import { axiosGet, axiosPost } from "./ajaxServices";
import { urlCreatePart, urlDeletePart, urlGetPartList, urlUpdatePart } from "./CONSTANTS";

export const apiGetPartList = () => {
  const url = urlGetPartList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiCreatePart(data) {
    const url = urlCreatePart;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("part_no",      data.part_no);
    formData.append("description",  data.description);
    formData.append("spring_qty",   data.spring_qty);
    formData.append("ippt_tot",     data.ippt_tot);
    formData.append("width",        data.width);
    formData.append("hdrmtrl",      data.hdrmtrl);
    formData.append("sglsglside",   data.sglsglside == undefined ? '':data.sglsglside);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

export function apiUpdatePart(data) {
    const url = urlUpdatePart;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id",           data.id);
    formData.append("part_no",      data.part_no);
    formData.append("description",  data.description);
    formData.append("spring_qty",   data.spring_qty);
    formData.append("ippt_tot",     data.ippt_tot);
    formData.append("width",        data.width);
    formData.append("hdrmtrl",      data.hdrmtrl);
    formData.append("sglsglside",   data.sglsglside == undefined ? '':data.sglsglside);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
  
  
  export function apiDeletePart(id) {
    const url = urlDeletePart;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", id);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }