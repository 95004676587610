import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import "./OrderDetailDialog.css"
import { isEmpty } from 'utils/GlobalFunctions';
import { Col, Input, message, Row, Modal, Radio } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import ProductDescriptionInput from "components/ProductPage/ProductDescriptionInput/ProductDescriptionInput";

const { TextArea } = Input;

function OrderDetailDialog(props) {
  const { show, proceed, partInfo, title, isEditing } = props

  const [selPart, setSelPart] = useState({});
  const [errorField, setErrorField] = useState([]);
  
  
  useEffect(() => {
    if (partInfo !== undefined) {
      setSelPart({ ...partInfo });
    } else {
      setSelPart({});
    }
  }, [partInfo]);


  
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-part-dlg"
      onOk={async () => {
        proceed({
          ...selPart,
        });
      }}
    >
      <div>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>W*</div>
          <ProductInputNumber
            min={"0"}
            field={"W"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Hd*</div>
          <ProductInputNumber
            min={"0"}
            field={"Hd"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16} style={{marginTop:10}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Ht*</div>
          <ProductInputNumber
            min={"0"}
            field={"Ht"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Hs*</div>
          <ProductInputNumber
            min={"0"}
            field={"Hs"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16} style={{marginTop:10}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>OX*</div>
          <ProductInputNumber
            min={"0"}
            field={"OX"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>OY*</div>
          <ProductInputNumber
            min={"0"}
            field={"OY"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16} style={{marginTop:10}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>DW*</div>
          <ProductInputNumber
            min={"0"}
            field={"DW"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>A*</div>
          <ProductInputNumber
            min={"0"}
            field={"A"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
      <Row align={"middle"} gutter={16} style={{marginTop:10}}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>(S)teel or (A)luminum Trailer*</div>
          <Radio.Group
            style={{ textAlign: "left", color: "var(--blackColor)" }}
            defaultValue = {'S'}
            value = {selPart['hdrmtrl']}
        >
            <Radio style={{ color: 'var(--blackColor)' }} value={'S'}>S</Radio>
            <Radio style={{ color: 'var(--blackColor)' }} value={'A'}>A</Radio>
        </Radio.Group>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>If single cable, which side?*</div>
          <Radio.Group
            style={{ textAlign: "left", color: "var(--blackColor)" }}
            defaultValue = {'Du'}
            value = {selPart['sglsglside']}
          >
            <Radio style={{ color: 'var(--blackColor)' }} value={'Du'}>Du</Radio>
            <Radio style={{ color: 'var(--blackColor)' }} value={'LH'}>LH</Radio>
            <Radio style={{ color: 'var(--blackColor)' }} value={'RH'}>RH</Radio>
        </Radio.Group>
        </Col>
      </Row>
    </div>
    </Modal>
  );
}

export default confirmable(OrderDetailDialog)
