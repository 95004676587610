import { axiosGet, axiosPost } from "./ajaxServices";
import { urlGetInternalUse, urlUpdateInternalUse, urlDeleteLMA, urlCreateLMA, urlUpdateLMA } from "./CONSTANTS";

export const apiGetInternalUse = () => {
  const url = urlGetInternalUse;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function apiUpdateInternalUse(data) {
  const url = urlUpdateInternalUse;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("Drum_Diameter", data.Drum_Diameter);
  formData.append("additional_turns", data.additional_turns);
  formData.append("Number_of_Springs", data.Number_of_Springs);
  formData.append("D2", data.D2);
  formData.append("Tolerances_IPPT", data.Tolerances_IPPT);
  formData.append("Tolerances_Width", data.Tolerances_Width);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}



export function apiDeleteLMA(id) {
  const url = urlDeleteLMA;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", id);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}



export function apiCreateLMA(data) {
  const url = urlCreateLMA;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("Drum_Dia", data.Drum_Dia);
  formData.append("LMA", data.LMA);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export function apiUpdateLMA(data) {
  const url = urlUpdateLMA;
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  var formData = new FormData();
  formData.append("id", data.id);
  formData.append("Drum_Dia", data.Drum_Dia);
  formData.append("LMA", data.LMA);
  return new Promise((resolve, reject) => {
    axiosPost(url, formData, config)
    .then(function (response) {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
  });
}