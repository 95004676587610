import React, { useEffect, useState } from 'react'
import { confirmable } from 'react-confirm'
import { isEmpty } from 'utils/GlobalFunctions';
import { Col, Input, message, Row, Modal } from "antd";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import "./AddLMADialog.css"

function AddLMADialog(props) {
  const { show, proceed, partInfo, title, isEditing } = props

  const [selPart, setSelPart] = useState({});
  const [errorField, setErrorField] = useState([]);
  useEffect(() => {
    if (partInfo !== undefined) {
      setSelPart({ ...partInfo });
    } else {
      setSelPart({});
    }
  }, [partInfo]);
  const validateFields = async () => {
    var errorList = Array();
    errorList = isEmpty(selPart, "Drum_Dia", errorList);
    errorList = isEmpty(selPart, "LMA", errorList);
    setErrorField([...errorList]);
    return errorList.length == 0;
  };
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      closable={true}
      maskClosable={false}
      title={title}
      className="custom-add-lma-dialog"
      onOk={async () => {

        if (isEditing === false && (await validateFields()) === false) return;
        proceed({
          ...selPart,
        });
      }}
    >
      <div>
      <Row align={"middle"} gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Drum Diameter*</div>
          <ProductInputNumber
            min={"0"}
            field={"Drum_Dia"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12}>
          <div>Lever Arm Distance*</div>
          <ProductInputNumber
            min={"0"}
            field={"LMA"}
            productInfo={selPart}
            setProductInfo={setSelPart}
            errorField={errorField}
            setErrorField={setErrorField}
          />
        </Col>
      </Row>
    </div>
    </Modal>
  );
}

export default confirmable(AddLMADialog)
