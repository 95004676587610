import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
import "./ContactTableWidget.css"
const { Option } = Select;

function TableWidget(props) {
    const { dataList, setDataList, onDetailAction, onDeleteAction, } = props;
    if (dataList === undefined) return <div />

    function createMarkup(html) {
        return { __html: html };
    }


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Customer Name",
            dataIndex: "full_name",
            sorter: {
                compare: (a, b) => a.full_name.localeCompare(b.full_name),
            },
        },
        {
            title: "Contact Info",
            dataIndex: "contact_info",
            sorter: {
                compare: (a, b) => a.contact_info.localeCompare(b.contact_info),
            },
        },
        {
            title: "Subject",
            dataIndex: "subject",
            sorter: {
                compare: (a, b) => a.subject.localeCompare(b.subject),
            },
        },
        {
            title: "Message",
            dataIndex: "message",
            sorter: {
                compare: (a, b) => a.message.localeCompare(b.message),
            },
            render: (text, record) => {

                return <div className='contact-message'>
                    {text}
                </div>
            },
        },
        {
            title: "Created Date",
            dataIndex: "add_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Updated Date",
            dataIndex: "update_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
        {
            title: "Action",
            key: "action",
            align: "center",
            render: (text, record) => {

                return <div size="middle" direction={"vertical"}>
                    <a style={{ color: "var(--redColor)"}} className="del-btn" onClick={async () => {
                        var res = await showConfirmDlgWidget({ title: "Delete Confirmation", content: "Are you sure to delete this record?" });
                        if (res == false) return;
                        onDeleteAction(record)
                    }}>
                        Delete
                    </a>
                </div>
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidget)
