import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Select } from "antd";
import { showConfirmDlgWidget } from 'components/ConfirmDlgWidget/showConfirmDlgWidget';
const { Option } = Select;

function TableWidget(props) {
    const { dataList, setDataList, onUpdateAction, onDeleteAction, } = props;
    if (dataList === undefined) return <div />

    function createMarkup(html) {
        return { __html: html };
    }


    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "Part No",
            dataIndex: "part_no",
            align: "center",
            sorter: {
                compare: (a, b) => a.part_no.localeCompare(b.part_no),
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            width : 400,
            sorter: {
                compare: (a, b) => a.description.localeCompare(b.description),
            },
            render: (text, record) => {
                return <div dangerouslySetInnerHTML={createMarkup(record.description)} />;
            },
        },
        {
            title: "Score",
            dataIndex: "score",
            align: "center",
            sorter: {
                compare: (a, b) => a.score - b.score,
            },
            render: text => <div> {parseFloat(text).toFixed(2)}</div>,
        },
        {
            title: "Spring Qty",
            dataIndex: "spring_qty",
            align: "center",
            sorter: {
                compare: (a, b) => a.spring_qty - b.spring_qty,
            },
        },
        {
            title: "IPPT Tot",
            dataIndex: "ippt_tot",
            align: "center",
            sorter: {
                compare: (a, b) => a.ippt_tot - b.ippt_tot,
            },
        },
        {
            title: "Width",
            dataIndex: "width",
            align: "center",
            sorter: {
                compare: (a, b) => a.width - b.width,
            },
            render: text => <div> {text}</div>,
        },
        {
            title: "HdrMtrl",
            dataIndex: "hdrmtrl",
            align: "center",
            sorter: {
                compare: (a, b) => a.hdrmtrl - b.hdrmtrl,
            },
        },
        {
            title: "SglSglSide",
            dataIndex: "sglsglside",
            align: "center",
            sorter: {
                compare: (a, b) => a.sglsglside - b.sglsglside,
            },
        },
        {
            title: "IPPT",
            dataIndex: "ippt",
            align: "center",
            sorter: {
                compare: (a, b) => a.ippt - b.ippt,
            },
        },
        {
            title: "IPPT Delta",
            dataIndex: "ippt_delta",
            align: "center",
            sorter: {
                compare: (a, b) => a.ippt_delta - b.ippt_delta,
            },
            render: text => <div> {parseFloat(text).toFixed(2)}</div>,
        },
        {
            title: "WidthOk",
            dataIndex: "width_ok",
            align: "center",
            sorter: {
                compare: (a, b) => a.width_ok - b.width_ok,
            },
        },
        {
            title: "Width Delta",
            dataIndex: "width_delta",
            align: "center",
            sorter: {
                compare: (a, b) => a.width_delta - b.width_delta,
            },
        },
        {
            title: "HdrMtrl2",
            dataIndex: "hdrmtrl2",
            align: "center",
            sorter: {
                compare: (a, b) => a.hdrmtrl2 - b.hdrmtrl2,
            },
        },
        {
            title: "SglSglSide2",
            dataIndex: "sglsglside2",
            align: "center",
            sorter: {
                compare: (a, b) => a.sglsglside2 - b.sglsglside2,
            },
        },
        {
            title: "Created Date",
            dataIndex: "add_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.add_timestamp.localeCompare(b.add_timestamp),
            },
        },
        {
            title: "Updated Date",
            dataIndex: "update_timestamp",
            align: "center",
            sorter: {
                compare: (a, b) => a.update_timestamp.localeCompare(b.update_timestamp),
            },
        },
    ];
    const dataSource = dataList.map((info) => {
        return { ...info, key: info.id };
    });

    return (
        <div className="list-table">
            <Table
                pagination={{ defaultPageSize: 3, showSizeChanger: true, pageSizeOptions: ['3', '6', '9'] }}
                columns={columns}
                dataSource={dataSource}
            />
        </div>
    )
}

export default withRouter(TableWidget)
