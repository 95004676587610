import { googleKey } from "config/CONSTANTS";

//export const BASE_URL               = "http://192.168.0.69/SpringCalcSystem";
export const BASE_URL               = "https://rampdoorsprings.com/backend/SpringCalcSystem";

//Admin backend base url
export const BASE_URL_ADMIN         = BASE_URL + "/admin";

export const urlUserLogin           = BASE_URL_ADMIN + "/main/login";

export const urlGetPartList         = BASE_URL_ADMIN + "/Part/all";
export const urlUpdatePart          = BASE_URL_ADMIN + "/Part/updatePart";
export const urlDeletePart          = BASE_URL_ADMIN + "/Part/deletePart";
export const urlCreatePart          = BASE_URL_ADMIN + "/Part/addPart";

export const urlGetInternalUse      = BASE_URL_ADMIN + "/InternalUse/getInternalUse";
export const urlUpdateInternalUse   = BASE_URL_ADMIN + "/InternalUse/updateInternalUse";

export const urlDeleteLMA           = BASE_URL_ADMIN + "/InternalUse/deleteLMA";
export const urlCreateLMA           = BASE_URL_ADMIN + "/InternalUse/addLMA";
export const urlUpdateLMA           = BASE_URL_ADMIN + "/InternalUse/updateLMA";


export const urlGetProfileInfo      = BASE_URL_ADMIN + "/Profile/GetProfileInfo";
export const urlUpdateProfileInfo   = BASE_URL_ADMIN + "/Profile/UpdateProfileInfo";

export const urlGetDashboardDetail  = BASE_URL_ADMIN + "/DashBoard/GetDashboardDetail";


export const urlGetOrderList         = BASE_URL_ADMIN + "/Order/all";
export const urlDeleteOrder          = BASE_URL_ADMIN + "/Order/deleteOrder";



export const urlGetContactList         = BASE_URL_ADMIN + "/ContactUs/all";
export const urlDeleteContact          = BASE_URL_ADMIN + "/ContactUs/deleteContactUs";
// //User backend base url
// export const BASE_URL_USER                  = BASE_URL + "/user";

// export const urlGetInternalUseForUser       = BASE_URL_USER + "/DashBoard/getInternalUse";
// export const urlGetPartListForUser          = BASE_URL_USER + "/DashBoard/getPartList";

