import React from 'react';
import "./TopWidget.css"
import { Row, Col } from "antd";

import { ReactComponent as storeIcon } from '../../../assets/icons/ic_store.svg';
import { ReactComponent as promotionIcon } from '../../../assets/icons/ic_promotion.svg';
import { ReactComponent as productIcon } from '../../../assets/icons/ic_product.svg';
import { ReactComponent as followerIcon } from '../../../assets/icons/ic_follower.svg';
import CategoryItemWidget from './CategoryItemWidget/CategoryItemWidget';
import { useHistory } from 'react-router';
import { ROUTE_INTERNAL_USE, ROUTE_PART, ROUTE_PROFILE, ROUTE_RECOMMEND } from 'navigation/CONSTANTS';
import { useDispatch } from 'react-redux';
import { UpdateCurrentRoot } from 'redux/actions/appActions';

const TopWidget = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <div>
            <Row align={'middle'} gutter={32}>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={storeIcon}
                        title="You will manage total Part Info"
                        count={''}
                        type="Part Info List"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_PART}))
                            history.push(ROUTE_PART)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={promotionIcon}
                        title="With Spring detail as you want, you will see best option "
                        count={''}
                        type="Recommend Spring"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_RECOMMEND}))
                            history.push(ROUTE_RECOMMEND)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={productIcon}
                        title="You will setup basic parameter value for spring calculation"
                        count={''}
                        type="Internal Use"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_INTERNAL_USE}))
                            history.push(ROUTE_INTERNAL_USE)
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <CategoryItemWidget
                        icon={followerIcon}
                        title="Super Admin Setup"
                        count={''}
                        type="Profile"
                        onClick={() => {
                            dispatch(UpdateCurrentRoot({ currentRoute: ROUTE_PROFILE}))
                            history.push(ROUTE_PROFILE)
                        }}
                    />
                </Col>

            </Row>
        </div>
    );
};

export default TopWidget;