import { axiosGet, axiosPost } from "./ajaxServices";
import { urlDeleteOrder, urlGetOrderList } from "./CONSTANTS";

export const apiGetOrderList = () => {
  const url = urlGetOrderList;
  return new Promise((resolve, reject) => {
    axiosGet(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

  
  
  export function apiDeleteOrder(id) {
    const url = urlDeleteOrder;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    var formData = new FormData();
    formData.append("id", id);
    return new Promise((resolve, reject) => {
      axiosPost(url, formData, config)
      .then(function (response) {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }