import { Col, message, Row, Spin } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import React, { useEffect, useState } from 'react'
import { apiDeleteOrder, apiGetOrderList } from "services/orderService";
import { showOrderDetailDialog } from "./OrderDetailDialog/showOrderDetailDialog";
import "./OrderPage.css"
import OrderTableWidget from "./OrderTableWidget/OrderTableWidget";


function OrderPage() {
    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetOrderList()
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
            });
    };

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.name.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };
    const onClickDelete = (pInfo) => {
        
        setIsBusy(true);
        apiDeleteOrder(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
                setSearchList(data)
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
                setDataList([]);
                setSearchList([]);
            });
    }
    const onDetailAction = async (pInfo) => {
        var res = await showOrderDetailDialog({
            title: "Order Detail from customer",
            partInfo : pInfo,
            isEditing: false
        });
    }
    
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>

                <div className="part-header">Order Info List
                    <div className="part-extra">
                        {/* <CommonButton onClick={() => {
                        }}>New Part</CommonButton> */}
                    </div>
                </div>
                <div className="part-search">
                    <TitleSearch
                        placeholder="Search by Customer Name"
                        onSearch={handleSearch}
                        onChange={handleChange}
                        onPressEnter={handleSearch}
                    />
                </div>

                <div>
                    <OrderTableWidget
                        dataList={searchList}
                        setDataList={setSearchList}
                        onDetailAction={onDetailAction}
                        onDeleteAction={onClickDelete}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    );
}

export default OrderPage
