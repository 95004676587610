import { message, Spin, Row, Col } from 'antd';
import CommonButton from 'components/ButtonWidgets/CommonButton/CommonButton';
import CommonDivWidget from 'components/CommonDivWidget/CommonDivWidget';
import ProductInputNumber from 'components/InputWidgets/InputNumber/ProductInputNumber';
import React from 'react';
import { useEffect, useState, } from "react";
import { apiCreateLMA, apiDeleteLMA, apiGetInternalUse, apiUpdateInternalUse, apiUpdateLMA } from 'services/internalUseService';
import { showAddLMADialog } from './AddLMADialog/showAddLMADialog';
import DistanceTableWidget from './DistanceTableWidget/DistanceTableWidget';
import "./InternalUsePage.css"

const InternalUsePage = (props) => {
    const [isBusy, setIsBusy] = useState(false);
    
    const [selPart, setSelPart] = useState({});
    const [dataList, setDataList] = useState([]);


    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsBusy(true);
        apiGetInternalUse()
            .then((data) => {
                setIsBusy(false);
                const { internalUse } = data === undefined ? { internalUse : undefined } : data;
                if (internalUse !== undefined) {
                    for(let i = 0; i < internalUse.length; i++) {
                        selPart[internalUse[i]['option_name']] = internalUse[i]['option_value']
                    }
                    setSelPart({...selPart})
                }               

                const { lma } = data === undefined ? { lma : undefined } : data;
                if (lma !== undefined) {
                    setDataList(lma)
                }
                
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry. " + err);
            });
    };


    const onClickDelete = (pInfo) => {
        console.log("pInfo", pInfo)
        setIsBusy(true);
        apiDeleteLMA(pInfo.id)
            .then((data) => {
                setIsBusy(false);
                setDataList(data);
            })
            .catch((err) => {
                setIsBusy(false);
                //message.error("Server error. " + err);
                setDataList([]);
            });
    }
    const onClickUpdate = async(pInfo) => {
        let info = pInfo
        var res = await showAddLMADialog({
          title: "Edit Part Info",
          partInfo: info,
          isEditing : true
        });
        if (res === null) return;
        setIsBusy(true);
        apiUpdateLMA(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while updating a LMA");
                }
                let updateData = [...dataList];
                var storeIndex = updateData.findIndex(x => x.id == data[0].id);
                updateData.splice(storeIndex, 1, data[0]);
                setDataList(updateData);
                message.success("LMA Info has been updated.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while updating a LMA. " + err);
                setDataList([]);
            });
    }
    const Save = () => {
        setIsBusy(true);
        apiUpdateInternalUse(selPart)
            .then((data) => {
                setIsBusy(false);
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("Sorry ! error. " + err);
            });
    }
    const addNewLMA = async () => {
        var res = await showAddLMADialog({
            title: "Create New LMA",
            isEditing: false
        });
        if (res === null) return;
        setIsBusy(true);
        apiCreateLMA(res)
            .then((data) => {
                setIsBusy(false);
                if (data === null) {
                    message.error("There's something wrong while creating a LMA");
                }
                let updateData = [...dataList];
                updateData = [...data, ...updateData];
                setDataList(updateData);
                message.success("New Part has been created.");
            })
            .catch((err) => {
                setIsBusy(false);
                message.error("There's something wrong while creating a LMA" + err)
            });

    }
    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>
                <div className="part-header">Internal Use Parameter
                    <div className="part-extra">
                        <CommonButton onClick={() => {
                            Save()
                        }}>Save</CommonButton>
                    </div>
                </div>
                <Row align={"middle"} gutter={[24, 16]} style={{ marginTop: 10 }} className="bg-border">
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row align={"top"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span style={{fontFamily: 'mediumFont'}}>Label</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span style={{fontFamily: 'mediumFont'}}>Dim</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span style={{fontFamily: 'mediumFont'}}>Value</span>
                            </Col>
                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:20}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span>Drum Diameter</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span>in</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"Drum_Diameter"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:20}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span>Additional Turns</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span>turns</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"additional_turns"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:20}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span>Number of Springs</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span>in</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"Number_of_Springs"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                        <Row align={"top"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span style={{fontFamily: 'mediumFont'}}>Label</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span style={{fontFamily: 'mediumFont'}}>Dim</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span style={{fontFamily: 'mediumFont'}}>Value</span>
                            </Col>
                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:20}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span>Assumed Intersect angle</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span>degrees</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"D2"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:20}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span>Tolerances IPPT</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span>+/-</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"Tolerances_IPPT"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                        </Row>
                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:20}}>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <span>Tolerances Width</span>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <span>inches</span>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"Tolerances_Width"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="part-header" style={{marginTop:20}}>Lever Arm Distance List
                    <div className="part-extra">
                        <CommonButton onClick={() => {
                            addNewLMA()
                        }}>New LMA</CommonButton>
                    </div>
                </div>
                <div>
                    <DistanceTableWidget
                        dataList={dataList}
                        setDataList={setDataList}
                        onUpdateAction={onClickUpdate}
                        onDeleteAction={onClickDelete}
                    />
                </div>
            </Spin>
        </CommonDivWidget>
    );
};

export default InternalUsePage;