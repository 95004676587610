import { Col, message, Row, Spin, Radio } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { TitleSearch } from "components/SearchWidget/TitleSearch";
import React, { useEffect, useState } from 'react'
import { apiGetPartList, } from "services/partService";
import "./RecommendPage.css"
import RecommendTableWidget from "./RecommendTableWidget/RecommendTableWidget";
import ProductInputNumber from "components/InputWidgets/InputNumber/ProductInputNumber";
import { apiGetInternalUse } from "services/internalUseService";


function RecommendPage() {
    // const W = 90                    // Hinged weight of the door, as measured with the door resting on a scale on the ground 'Spring Calc'!C6
    // const Hd = 75                   // Height of the door from the hinge to the top of the door 'Spring Calc'!C7
    // const Ht = 15                   // Height of the trailer from the ground to the door hinge    'Spring Calc'!C8
    // const Hs = 75                   // Height for the hinge to the center of spring system tube 'Spring Calc'!C9
    // const OX = 5                    // Offset from the top of the closed door vertically to the center of the center of the spring system tube (normally 4", measure only if spring mounting bracket is custom)    'Spring Calc'!C10
    // const OY = 5                    // Offset from the outside of the rear trailer frame, inside to the center of the spring system tube (normally 4", measure only if spring mounting bracket is custom)    'Spring Calc'!C11
    // const DW = 88                   // Door width    'Spring Calc'!C12
    // const A = 12                    // Distance from the end of the door to the cable attachment point    'Spring Calc'!C13
    
    // const hdrmtrl = "S"             // (S)teel or (A)luminum Trailer
    // const sglsglside = ""           // LH = Street side, RH = Curbside (Leave blank if dual cable)

    // const Drum_Diameter = 4         // Drum Diameter 'Spring Calc'!C16
    // const additional_turns = 1.5    // Additional Turns 'Spring Calc'!C17
    // const Number_of_Springs = 2     // Number of springs 'Spring Calc'!C18

    // //Calculations tab constant    
    // const D2 = 45 //'Calculations'!D2

    // const Tolerances_IPPT = 0.1
    // const Tolerances_Width = 2


    const [isBusy, setIsBusy] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [selPart, setSelPart] = useState({});
    const [LeverArmDistance, setLeverArmDistance] = useState([])


    useEffect(() => {
        if (dataList.length != 0) {
            setDataList([])
            setSearchList([])
        }
    }, [selPart]);

    useEffect(() => {
        getInternalUseData()
    }, []);

    const getInternalUseData = () => {
        setIsBusy(true);
        apiGetInternalUse()
            .then((data) => {
                const { internalUse } = data === undefined ? { internalUse : undefined } : data;
                if (internalUse !== undefined) {
                    for(let i = 0; i < internalUse.length; i++) {
                        selPart[internalUse[i]['option_name']] = parseFloat(internalUse[i]['option_value'])
                    }
                    setSelPart({...selPart})
                }
                
                if (selPart['sglsglside'] === undefined) {
                    selPart['sglsglside'] = ''
                }               
                if (selPart['hdrmtrl'] === undefined) {
                    selPart['hdrmtrl'] = 'S'
                }
                selPart['OX'] = 5
                selPart['OY'] = 5
                setSelPart({...selPart})

                const { lma } = data === undefined ? { lma : undefined } : data;
                if (lma !== undefined) {
                    let tmpList = []
                    for(let i = 0; i < lma.length; i++) {
                        let tmp = {Drum_Dia : lma[i]['Drum_Dia'], LMA : lma[i]['LMA']}
                        tmpList = [...tmpList, tmp]
                    }                
                    setLeverArmDistance(tmpList)
                }
                setIsBusy(false);
            })
            .catch((err) => {
                setIsBusy(false);
                 message.error("Sorry. " + err);
            });
    };
    const Calc_Assumed_Intersect_angle = () => {
        //Assumed Intersect angle is 45 degreed error<.2%
        let result = selPart['D2'] * Math.PI / 180
        return result
    }
    const Calc_Q = () => {
        //Q =ASIN('Spring Calc'!C8/'Spring Calc'!C7)
        let result = Math.asin(selPart['Ht'] / selPart['Hd'])
        return result
    }
    const Calc_a = () => {
        //a
        let result = parseFloat(selPart['Hd'] - selPart['A']) * Math.cos(Calc_Q())
        return result
    }
    const Calc_x_intersect = () => {
        //x intersect, intersection point between cable(C)and drum 
        let result = 0
        if (selPart['OX'] > 0 && LeverArmDistance.length != 0) {
            var storeIndex = LeverArmDistance.findIndex(x => x["Drum_Dia"] == selPart['Drum_Diameter']);
            if (storeIndex == -1) {
                message.error("Can't find Drum Diameter on LeverArmDistance - Calc_x_intersect")
            }
            else {
                result = parseFloat(selPart['OX']) + parseFloat(LeverArmDistance[storeIndex]["LMA"] * Math.sin(Calc_Assumed_Intersect_angle()))
            }
        }
        return result
    }
    const Calc_y_intersect = () => {
        //x intersect, intersection point between cable(C)and drum 
        let result = 0
        if (selPart['OY'] > 0 && LeverArmDistance.length != 0) {
            var storeIndex = LeverArmDistance.findIndex(x => x["Drum_Dia"] == selPart['Drum_Diameter']);
            if (storeIndex == -1 && LeverArmDistance.length != 0) {
                message.error("Can't find Drum Diameter on LeverArmDistance - Calc_y_intersect")
            }
            else {
                result = selPart['OY'] - LeverArmDistance[storeIndex]["LMA"] * Math.cos(Calc_Assumed_Intersect_angle())
            }
        }
        return result
    }

    const Calc_a_plus_x_intersect = () => {
        //a+x intersect =B6+B3
        let result = Calc_a() + Calc_x_intersect()
        return result
    }
    const Calc_I = () => {
        let result = selPart['A'] * Math.sin(Calc_Q())
        return result
    }
    const Calc_H_minus_I = () => {
        let result = selPart['Ht'] - Calc_I()
        return result
    }
    const Calc_P_plus_Q = () => {
        //P+Q
        let result = Math.atan((Calc_H_minus_I() + Calc_y_intersect() + parseFloat(selPart['Hs'])) / (Calc_a() + Calc_x_intersect()))
        return result
    }
    const Calc_P = () => {
        //P
        let result = Calc_P_plus_Q() - Calc_Q()
        return result
    }
    const Calc_C = () => {
        //C
        let result = Calc_a_plus_x_intersect() / Math.cos(Calc_P_plus_Q())
        return result
    }
    const Calc_Drum_Circ = () => {
        //Drum Circ
        let result = 0
        if (LeverArmDistance.length != 0) {
            var storeIndex = LeverArmDistance.findIndex(x => x["Drum_Dia"] == selPart['Drum_Diameter']);
            if (storeIndex == -1) {
                message.error("Can't find Drum Diameter on LeverArmDistance - Calc_Drum_Circ")
            }
            else {
                result = 2 * Math.PI * LeverArmDistance[storeIndex]["LMA"]
            }
        }
        return result
    }
    const Calc_Turns = () => {
        //Turns
        let result = Calc_C() / Calc_Drum_Circ()
        return result
    }
    const Calc_TotalCableLength = () => {
        //Total Cable length	133.41	in = B12+B13*'Spring Calc'!C17
        let result = parseFloat(Calc_C()) + Calc_Drum_Circ() * selPart['additional_turns'] 
        return result
    }
    const Calc_B18 = () => {
        let result = (selPart['W'] * selPart['Hd'] * Math.cos(Calc_Q())) / ((selPart['Hd'] - selPart['A']) * Math.sin(Calc_P()))
        return result
    }
    const Calc_SF = () => {
        let result = Calc_B18() * (selPart['Drum_Diameter'] / 2)
        return result
    }
    const Calc_Force_Spring = () => {
        let result = Calc_SF() / selPart['Number_of_Springs']
        return result
    }
    const Calc_Calulated_Spring_rate = () => {
        let result = Calc_Force_Spring() / Calc_Turns()
        return result
    }
    const Calc_Total_Calculated_Spring_force = () => {
        let result = Calc_Calulated_Spring_rate() * Calc_Turns() * selPart['Number_of_Springs']
        return result
    }

    const getData = () => {
        setIsBusy(true);
        apiGetPartList()
            .then((data) => {
                prepareData(data)
            })
            .catch((err) => {
                setIsBusy(false);
                // message.error("Server failed. " + err);
            });
    };
    const prepareData = (data) => {
        let tmp = []
        for (let i = 0; i < data.length; i++) {

            //ippt calculation
            let value_0 = Calc_Total_Calculated_Spring_force()/Calc_Turns()
            if (value_0 >= data[i]["ippt_tot"] * (1 - selPart['Tolerances_IPPT']) && value_0 <= data[i]["ippt_tot"] * (1 + parseFloat(selPart['Tolerances_IPPT']))) {
                data[i]["ippt"] = 1
            }
            else {
                data[i]["ippt"] = 0
            }

            //ippt delta
            data[i]["ippt_delta"] = data[i]["ippt_tot"] - value_0

            //width ok
            if (parseFloat(selPart['DW']) <= (parseFloat(data[i]["width"]) + parseFloat(selPart['Tolerances_Width']))) {
                data[i]["width_ok"] = 1
            }
            else {
                data[i]["width_ok"] = 0
            }

            //width delta
            data[i]["width_delta"] = data[i]["width"] - selPart['DW']

            //HdrMtrl2
            if (selPart['hdrmtrl'] == data[i]["hdrmtrl"]) {
                data[i]["hdrmtrl2"] = 1
            }
            else {
                data[i]["hdrmtrl2"] = 0
            }

            //SglSglSide2
            if (selPart['sglsglside'] == data[i]["sglsglside"]) {
                data[i]["sglsglside2"] = 1
            }
            else {
                data[i]["sglsglside2"] = 0
            }

            //score
            let score = Math.abs(data[i]["ippt"] * data[i]["ippt_delta"] * data[i]["width_ok"] * data[i]["width_delta"] * data[i]["hdrmtrl2"] * data[i]["sglsglside2"])
            if (parseInt(score) > 0) {
                data[i]["score"] = score 
                tmp.push(data[i])
            }
            
            
        }
        tmp.sort((a, b) => (a.score > b.score) ? 1 : -1)
        setIsBusy(false);
        setDataList(tmp);
        setSearchList(tmp)
    }

    const handleSearch = (searchText) => {
        const tmpList = dataList.filter((info) => info.part_no.toLowerCase().includes(searchText))
        setSearchList([...tmpList])
    };
    const handleChange = (event) => {
        if (event.target.value === "") {
            setSearchList(dataList)
        }
        else {
            handleSearch(event.target.value)
        }
    };

    return (
        <CommonDivWidget>
            <Spin spinning={isBusy}>

                <div className="part-header">Spring System Specification</div>
                <Row align={"middle"} gutter={[24, 16]} style={{ marginTop: 10 }} className="bg-border">
                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                        <img
                            style={{maxWidth:"100%"}}
                            src={'/assets/images/spring.png'}
                            alt="SpringCalcSystem"
                        />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                        <Row align={"top"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span style={{fontFamily: 'mediumFont'}}>Label</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span style={{fontFamily: 'mediumFont'}}>Dim</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <span style={{fontFamily: 'mediumFont'}}>Value</span>
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span style={{fontFamily: 'mediumFont'}}>Description</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:20}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>W</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>lbs</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"W"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Hinged weight of the door, as measured with the door resting on a scale on the ground</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>Hd</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>in</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"Hd"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Height of the door from the hinge to the top of the door</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>Ht</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>in</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"Ht"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Height of the trailer from the ground to the door hinge</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>Hs</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>In</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"Hs"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Height for the hinge to the center of spring system tube</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>Ox</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>In</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"OX"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Offset from the top of the closed door vertically to the center of the center of the spring system tube (normally 4", measure only if spring mounting bracket is custom)</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>Oy</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>In</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"OY"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Offset from the outside of the rear trailer frame, inside to the center of the spring system tube (normally 4", measure only if spring mounting bracket is custom)</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>DW</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>in</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"DW"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Door width</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>A</span>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <span>in</span>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <ProductInputNumber
                                    min={"0"}
                                    field={"A"}
                                    productInfo={selPart}
                                    setProductInfo={setSelPart}
                                />
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>Distance from the end of the door to the cable attachment point</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>(S)teel or (A)luminum Trailer</span>
                            </Col>
                            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                <Radio.Group
                                    style={{ textAlign: "left", color: "var(--blackColor)" }}
                                    onChange={(e) =>
                                        setSelPart({ ...selPart, hdrmtrl : e.target.value })
                                    }
                                    defaultValue = {'S'}
                                    value = {selPart['hdrmtrl']}
                                >
                                    <Radio style={{ color: 'var(--blackColor)' }} value={'S'}>S</Radio>
                                    <Radio style={{ color: 'var(--blackColor)' }} value={'A'}>A</Radio>
                                </Radio.Group>
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>(S)teel or (A)luminum Trailer</span>
                            </Col>
                        </Row>

                        <Row align={"middle"} gutter={[24, 16]} style={{marginTop:10}}>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <span>If single cable, which side?</span>
                            </Col>
                            <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                <Radio.Group
                                    style={{ textAlign: "left", color: "var(--blackColor)" }}
                                    onChange={(e) =>
                                        setSelPart({ ...selPart, sglsglside : e.target.value })
                                    }
                                    defaultValue = {''}
                                    value = {selPart['sglsglside']}
                                >
                                    <Radio style={{ color: 'var(--blackColor)' }} value={''}>Du</Radio>
                                    <Radio style={{ color: 'var(--blackColor)' }} value={'LH'}>LH</Radio>
                                    <Radio style={{ color: 'var(--blackColor)' }} value={'RH'}>RH</Radio>
                                </Radio.Group>
                            </Col>
                            <Col xs={13} sm={13} md={13} lg={13} xl={13}>
                                <span>LH = Street side, RH = Curbside (Leave blank if dual cable)</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="part-header" style={{ marginTop: 30 }}>Spring System Result</div>
                <Row align={"top"} gutter={[24, 16]} className="bg-border">
                    <Col xs={24} sm={24} md={24} lg={24} xl={9}>
                        <p style={{fontFamily:'mediumFont', fontSize:16}}>Caculation Result</p>
                        <div>
                        <div>
                            <span>Assumed Intersect angle is 45 degreed error :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_Assumed_Intersect_angle().toFixed(3)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; radians</span>
                        </div>
                        <div>
                            <span>X intersect, intersection point between cable(C)and drum :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_x_intersect().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>Y intersect, intersection point between cable(C)and drum :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_y_intersect().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>Q :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_Q().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; radians</span>
                        </div>
                        <div>
                            <span>a :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_a().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>a+x intersect :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_a_plus_x_intersect().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>I :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_I().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>H-I :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_H_minus_I().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>P+Q :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_P_plus_Q().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; radians</span>
                        </div>
                        <div>
                            <span>P :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_P().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; radians</span>
                        </div>
                        <div>
                            <span>C :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_C().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>Drum Circ :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_Drum_Circ().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>Turns :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_Turns().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; turns</span>
                        </div>
                        <div>
                            <span>Total Cable length :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_TotalCableLength().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; in</span>
                        </div>
                        <div>
                            <span>{"T=-{wt*D*cos(Q)}/(D-0S)*Sin(P)"} :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_B18().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; lbs</span>
                        </div>
                        <div>
                            <span>{"SF=T*Drum dia/2"} :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_SF().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; lb.in</span>
                        </div>
                        <div>
                            <span>{"Force/spring"} :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_Force_Spring().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; lb.in</span>
                        </div>
                        <div>
                            <span>{"Calulated Spring rate"} :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_Calulated_Spring_rate().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; lb.in/turn</span>
                        </div>
                        <div>
                            <span>{"Total Calculated Spring force "} :  </span>
                            <span style={{ color: "var(--blueColor)" }}>
                                {
                                    Calc_Total_Calculated_Spring_force().toFixed(2)
                                }
                            </span>
                            <span style={{ color: "var(--orangeColor)" }}> &nbsp; lb.in</span>
                        </div>
                        <div style={{marginTop:20, marginBottom:20}}>
                            <CommonButton onClick={() => {
                                getData()
                            }}>Recommend Spring</CommonButton>
                        </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={15}>
                        <p style={{fontFamily:'mediumFont', fontSize:16}}>Recommend Spring List</p>
                        <div className="part-search">
                        <TitleSearch
                            placeholder="Search by Part No"
                            onSearch={handleSearch}
                            onChange={handleChange}
                            onPressEnter={handleSearch}
                        />
                        </div>
                        <div>
                            <RecommendTableWidget
                                dataList={searchList}
                                setDataList={setSearchList}
                            />
                        </div>
                    </Col>
                </Row>
            </Spin>
        </CommonDivWidget>
    );
}

export default RecommendPage
